import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Breadcrumbs from '@shared/components/breadcrumbs';
import DatePicker from '@pages/statistics/modal/monthSelector';
import React, { useEffect, useRef, useState } from 'react';
import StatsIndicator from '@pages/statistics/modal/statsIndicator';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@shared/components/circular-progress';
import selectStats from '@store/stats/selectors';
import { fetchMonthlyStats } from 'store/stats/actions';
import SelectorDropdown from '@pages/statistics/modal/selector';
import { isAdmin } from '@shared/models';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem',
    height: '100%',
    gap: '30px',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
  },
  header: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'row',
    color: '#334D6E',
    fontSize: '22px',
    fontWeight: '700',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    color: '#334D6E',
    marginLeft: '50%',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '25px',
  },
  headerText: {
    color: '#334D6E',
    fontSize: '22px',
    fontWeight: '700',
  },
}));

const BREADCRUMBS_ITEMS = [{ name: 'Usage & Limits' }];

const UsageAndLimits = () => {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [virtualRoom, setVirtualRoom] = useState({
    limitVirtualRoomState: 0,
    viewsVirtualRoomState: 0,
  });
  const [userRoom, setUserRoom] = useState({
    limitUserRoomState: 0,
    viewsUserRoomState: 0,
  });
  const [configurator, setConfigurator] = useState({
    limitConfiguratorState: 0,
    viewsConfiguratorState: 0,
  });
  const [loading, setLoading] = useState(false);
  const companySelector = (state) => state.app.company;
  const curUser = useSelector((state) => state.app.user);
  const curCompany = useSelector(companySelector);
  const dispatch = useDispatch();
  const stats = useSelector(selectStats);
  const companies = useSelector((state) => state.companies.companies);
  const [selectedCompany, setSelectedCompany] = useState(curCompany);

  useEffect(() => {
    if (curCompany && currentDate) {
      const payload = {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        companyId: curCompany.id,
      };
      dispatch(fetchMonthlyStats(payload));
    }
  }, [curCompany, currentDate, dispatch]);


  useEffect(() => {
    if (selectedCompany && currentDate) {
      const payload = {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        companyId: selectedCompany.id,
      };
      dispatch(fetchMonthlyStats(payload));
    }
  }, [selectedCompany, currentDate, dispatch]);

  const debounced = useRef(
    (stats, currentCP) => {
      const isAdminUser = isAdmin(curUser);
      const getStatValue = (sType) => stats.filter((i) => i._id?.type === sType).map((i) => i.sumValue).find(() => true);
      if (stats) {
        const cpStats = getStatValue('configure_product');
        const prStats = getStatValue('open_scene_predefined');
        const myStats = getStatValue('open_scene_my');
        setConfigurator({
          limitConfiguratorState: isAdminUser ? currentCP?.productViews || 0 : curCompany?.productViews || 0,
          viewsConfiguratorState: cpStats || 0,
        });
        setVirtualRoom({
          limitVirtualRoomState: isAdminUser ? currentCP?.designedRoomViews || 0 : curCompany?.designedRoomViews || 0,
          viewsVirtualRoomState: prStats || 0,
        });
        setUserRoom({
          limitUserRoomState: isAdminUser ? currentCP?.userRoomViews || 0 : curCompany?.userRoomViews || 0,
          viewsUserRoomState: myStats || 0,
        });
      }
      setLoading(false);
    },
  );

  useEffect(() => {
    setLoading(true);
    const currentCP = selectedCompany;
    const handler = setTimeout(() => {
      debounced.current(stats, currentCP);
    }, 800);
    return () => clearTimeout(handler);
  }, [stats]);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ BREADCRUMBS_ITEMS } />
          <div className={ classes.headerRow }>
            {isAdmin(curUser) && (
            <SelectorDropdown companies={ companies } onSelectCompany={ setSelectedCompany } curCompany={ curCompany } />
            )}
            <DatePicker setCurrentDate={ setCurrentDate } currentDate={ currentDate } />
          </div>
        </TopNav>
      </PageHead>
      <PageContent>
        {loading ? (
          <CircularProgress
            className={ classes.loader }
            size="large"
          />
        ) : (
          <Paper className={ classes.root }>
            <div className={ classes.header }>
              <img
                src="/assets/images/icons/stats.svg"
                alt="stats"
              />
              <span className={ classes.headerText }>Usage Statistics</span>
            </div>
            <StatsIndicator
              limit={ virtualRoom?.limitVirtualRoomState }
              currentAmount={ virtualRoom?.viewsVirtualRoomState }
              limitName="Showroom: Virtual Room"
            />
            <StatsIndicator
              limit={ userRoom?.limitUserRoomState }
              currentAmount={ userRoom?.viewsUserRoomState }
              limitName="Showroom: Your Room"
            />
            <StatsIndicator
              limit={ configurator.limitConfiguratorState }
              currentAmount={ configurator.viewsConfiguratorState }
              limitName="3D Configurator"
            />
          </Paper>
        )}

      </PageContent>
    </PageContainer>
  );
};

export default UsageAndLimits;
