import * as React from 'react';

const StatsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="19"
    fill="none"
    viewBox="0 0 23 19"
  >
    <path
      fill="#9CACC2"
      stroke="#9CACC2"
      strokeWidth="0.2"
      d="M18.19 1.027A.44.44 0 0 1 18.496.9h3.59c.116 0
      .227.046.308.127m-4.206 0h4.206m-4.206 0a.43.43 0 0
      0-.127.308v16.33a.435.435 0 0 0 .435.435h3.59a.435.435
      0 0 0 .436-.435V1.335a.44.44 0 0 0-.128-.308m-4.206
      0h4.206M5.233 17.973l-.07-.071.07.07a.44.44 0 0 0
      .128-.307v-7.613a.435.435 0 0 0-.435-.435H1.335a.435.435
      0 0 0-.435.434v7.614a.435.435 0 0 0 .435.435h3.59a.44.44
      0 0 0 .308-.127Zm1.515 0 .07-.071-.07.07a.44.44 0 0 0
      .307.128h3.592a.435.435 0 0 0 .434-.435V3.591a.435.435
      0 0 0-.434-.435H7.055a.435.435 0 0 0-.434.435v14.074c0
      .116.045.226.127.308Zm-2.257-.742H1.77v-6.745h2.722zm5.72
      0h-2.72V4.024h2.72zm6.156-10.172h-3.591a.435.435 0 0
      0-.435.435v10.171a.435.435 0 0 0 .435.435h3.591a.435.435
      0 0 0 .435-.435V7.495a.434.434 0 0 0-.435-.436Zm-.435
      10.171h-2.721V7.93h2.721zm5.721 0h-2.722V1.77h2.722z"
    />
  </svg>
);

export default StatsIcon;
