export const FETCH_MONTHLY_STATS = 'FETCH_MONTHLY_STATS';
export const SET_STATS = 'SET_STATS';
export const FETCH_STATS_FAILED = 'FETCH_STATS_FAILED';


export function fetchMonthlyStats(payload) {
  return {
    type: FETCH_MONTHLY_STATS,
    payload,
  };
}

export function setStats(stats) {
  return {
    type: SET_STATS,
    payload: stats,
  };
}


export function fetchStatsFailed(error) {
  return {
    type: FETCH_STATS_FAILED,
    payload: error,
  };
}
