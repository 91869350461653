import { call, put, all, takeEvery } from 'redux-saga/effects';
import { statsApi } from '@api';
import {SET_STATS, FETCH_STATS_FAILED, FETCH_MONTHLY_STATS, setStats} from './actions.js';

export function* fetchMonthlyStatsSaga({ payload }) {
  const { year, month, companyId } = payload;

  try {
    const stats = yield call(
      statsApi.getMonthlyStats,
      year,
      month,
      companyId,
    );

    yield put(setStats(stats));
  } catch (error) {
    console.error('Error fetching monthly stats:', error);

    yield put({ type: FETCH_STATS_FAILED, payload: error });
  }
}

export default function* statsSaga() {
  yield all([
    takeEvery(FETCH_MONTHLY_STATS, fetchMonthlyStatsSaga),

  ]);
}

