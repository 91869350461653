import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
    justifyContent: 'space-between',
    display: 'flex',
    userSelect: 'none',
    flexDirection: 'column',
    border: 'none',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  header: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#334D6E',
  },
  numbers: {
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#334D6E',
  },
  numbers1: {
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 700,
    color: '#334D6E',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    //gap: '15px',
  },
}));

const StatsIndicator = ({ limit, currentAmount, limitName }) => {
  const fillPercentage = (currentAmount / limit) * 100;
  const isExceedingLimit = fillPercentage > 100;
  const barWidth = Math.min(fillPercentage, 100); // Cap the width to 100% for display
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <span className={ classes.header }>{limitName}</span>
      <div className={ classes.row }>
        <div
          style={ {
            width: '290px',
            height: '9px',
            backgroundColor: 'transparent',
            border: isExceedingLimit ? '1px solid #FF4D50' : '1px solid #9CACC2',
            borderRadius: '6px',
          } }
        >
          <div
            style={ {
              width: `${barWidth}%`,
              height: '100%',
              backgroundColor: isExceedingLimit ? '#FF4D50' : '#334D6E',
              borderRadius: '3px',
            } }
          />
        </div>
        <span
          className={ classes.numbers1 }
          style={ {
            color: isExceedingLimit ? '#FF4D50' : '#334D6E',
            marginLeft: '15px',
          } }
        >{currentAmount}
        </span>
        <span
          className={ classes.numbers }
          style={ {
            marginLeft: '2px',
          } }
        >/{limit} views
        </span>
      </div>
    </div>
  );
};

export default StatsIndicator;
