const STATS_ENDPOINT = '/stats';

class StatsApi {
  constructor(http) {
    this.http = http;
  }

  /**
   * Fetch stats for a specific month and year for a company.
   * @param {number} year - The year of the stats.
   * @param {number} month - The month of the stats.
   * @param {string} companyId - The ID of the company.
   * @returns {Promise<any>} - Return  data.
   */
  getMonthlyStats = async (year, month, companyId) => {
    const query = new URLSearchParams({
      year,
      month,
      company: companyId,
    }).toString();
    const data = await this.http.get(`${STATS_ENDPOINT}/month?&${query}`);
    return data;
  }
}

export default StatsApi;
