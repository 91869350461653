import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '156px',
    height: '31px',
    position: 'relative',
    justifyContent: 'space-between',
    display: 'flex',
    userSelect: 'none',
    flexDirection: 'row',
    border: 'none',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  button: {
    background: '#334D6E',
    display: 'flex',
    justifyContent: 'center',
    width: '26px',
    color: 'white',
    fontSize: '18px',
  },
  date: {
    margin: '4px',
    color: '#334D6E',
    fontSize: '14px',
    fontWeight: '400',
    marginRight: '8px',
    marginLeft: '8px',
  },
}));

const DatePicker = ({ setCurrentDate, currentDate }) => {
  const classes = useStyles();

  const handlePrevMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() - 1);
    setCurrentDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  const currentMonthYear = currentDate.toLocaleString('en-US', { month: 'short', year: 'numeric' });

  return (
    <div className={ classes.root }>
      <div className={ classes.button } onClick={ handlePrevMonth }>
        ←
      </div>
      <span className={ classes.date }>{currentMonthYear}</span>
      <div className={ classes.button } onClick={ handleNextMonth }>
        →
      </div>
    </div>
  );
};

export default DatePicker;

