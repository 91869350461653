import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { productsApi } from '@api';
import {
  LOAD_PRODUCTS_LIST,
  LOAD_PRODUCTS_COUNTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_VARIANT,
  UPDATE_VARIANT,
  DELETE_VARIANT,
  loadProductsList,
  renderProductsList,
  renderProductsCounts,
  renderProduct,
  MARK_PRODUCT_AS_DEFAULT_CONTENT,
  UNMARK_PRODUCT_AS_DEFAULT_CONTENT,
  HIDE_PRODUCT,
  createVariantSuccess,
  deleteProductError,
  updateVariantError,
  createVariantError,
  updateProductError,
  updateProductSuccess,
  createProductError,
  createProductSuccess,
} from './actions';
import { selectCurrentCompany } from '@store/app/selectors';
import addHiddenStateForItems from '@shared/utils/addHiddenStateForItems';
import { deleteVariantSuccess, updateVariantSuccess } from '@store/products/actions';
import { actions as appActions } from '@store/app/index';

export function* fetchProductsList() {
  try {
    const data = yield call(productsApi.getProducts);
    const company = yield select(selectCurrentCompany);
    yield put(renderProductsList(addHiddenStateForItems(data, company)));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchProductsCounts() {
  try {
    const data = yield call(productsApi.getProductsCounts);
    yield put(renderProductsCounts(data));
  } catch (error) {
    console.error(error);
  }
}

//TODO: there is unoptimal solution because of create/update response
function* saveProductSuccess(response) {
  const allProducts = yield call(productsApi.getProducts);
  const company = yield select(selectCurrentCompany);
  const adjustedProducts = addHiddenStateForItems(allProducts, company);
  const currentProduct = (Array.isArray(response) ? adjustedProducts : adjustedProducts.items)
    .find((item) => item.id === response.id);

  if (currentProduct) {
    yield put(renderProduct(currentProduct));
  }
  yield put(renderProductsList(adjustedProducts));
}

export function* fetchPostProduct({ product, setError }) {
  try {
    const data = yield call(productsApi.createProduct, product);
    yield saveProductSuccess(data);
    yield put(createProductSuccess());
  } catch (error) {
    if (error.response?.data) {
      const apiError = error.response?.data;
      const titles = apiError.message.map((message) => {
        if (message.reason === 'unique') {
          setError(message.property, {});
          return `Property '${message.property}' should be unique`;
        }
      });
      yield put(appActions.addMessage({ message: titles.join('\n'), type: 'error' }));
      yield put(createProductError(apiError));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during creating the Product', type: 'error' }));
  }
}

export function* fetchPutProduct({ product, setError }) {
  try {
    const data = yield call(productsApi.editProduct, product);
    yield saveProductSuccess(data);
    yield put(updateProductSuccess());
  } catch (error) {
    if (error.response?.data) {
      const apiError = error.response?.data;
      const titles = apiError.message.map((message) => {
        if (message.reason === 'unique') {
          setError(message.property, {});
          return `Property '${message.property}' should be unique`;
        }
      });
      yield put(appActions.addMessage({ message: titles.join('\n'), type: 'error' }));
      yield put(updateProductError(apiError));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during saving the Product', type: 'error' }));
  }
}

export function* fetchDeleteProduct({ id }) {
  try {
    yield call(productsApi.deleteProduct, id);
    yield put(loadProductsList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteProductError(id, error.response?.data));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during removing the Product', type: 'error' }));
  }
}

export function* fetchPostVariant({ variant, setError }) {
  try {
    const data = yield call(productsApi.createVariant, variant);

    yield put(createVariantSuccess(data));
    yield put(loadProductsList());
  } catch (error) {
    if (error.response?.data) {
      const apiError = error.response?.data;
      const titles = apiError.message.map((message) => {
        if (message.reason === 'unique') {
          setError(message.property, { type: 'manual', message: `Property '${message.property}' should be unique` });
          return `Property '${message.property}' should be unique`;
        }
      });
      yield put(appActions.addMessage({ message: titles.join('\n'), type: 'error' }));
      yield put(createVariantError(apiError));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during creating the Variation', type: 'error' }));
  }
}

export function* fetchPutVariant({ variant, setError }) {
  try {
    const data = yield call(productsApi.editVariant, variant);

    yield put(updateVariantSuccess(data));
    yield put(loadProductsList());
  } catch (error) {
    if (error.response?.data) {
      const apiError = error.response?.data;
      const titles = apiError.message.map((message) => {
        if (message.reason === 'unique') {
          setError(message.property, {});
          return `Property '${message.property}' should be unique`;
        }
      });
      yield put(appActions.addMessage({ message: titles.join('\n'), type: 'error' }));
      yield put(updateVariantError(apiError));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during saving the Variation', type: 'error' }));
  }
}

export function* fetchDeleteVariant({ id, parentProductId }) {
  try {
    yield call(productsApi.deleteVariant, id);

    yield put(deleteVariantSuccess(id, parentProductId));
    yield put(loadProductsList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteProductError(id, error.response?.data));
      return;
    }
    console.error(error);
    yield put(appActions.addMessage({ message: 'Unexpected error during removing the Variation', type: 'error' }));
  }
}

export function* markProductAsDefaultContent({ payload: product }) {
  try {
    yield call(productsApi.markProductAsDefaultContent, product.id);
    yield put(loadProductsList());
  } catch (error) {
    console.error(error);
  }
}

export function* unmarkProductAsDefaultContent({ payload: product }) {
  try {
    yield call(productsApi.unmarkProductAsDefaultContent, product.id);
    yield put(loadProductsList());
  } catch (error) {
    console.error(error);
  }
}

export function* hideProduct({ payload: product }) {
  try {
    yield call(productsApi.hideProduct, product.id);
    yield put(loadProductsList());
  } catch (error) {
    console.error(error);
  }
}

export default function* productsSaga() {
  yield all([
    takeEvery(LOAD_PRODUCTS_LIST, fetchProductsList),
    takeEvery(LOAD_PRODUCTS_COUNTS, fetchProductsCounts),
    takeEvery(CREATE_PRODUCT, fetchPostProduct),
    takeEvery(UPDATE_PRODUCT, fetchPutProduct),
    takeEvery(DELETE_PRODUCT, fetchDeleteProduct),
    takeEvery(CREATE_VARIANT, fetchPostVariant),
    takeEvery(UPDATE_VARIANT, fetchPutVariant),
    takeEvery(DELETE_VARIANT, fetchDeleteVariant),
    takeEvery(MARK_PRODUCT_AS_DEFAULT_CONTENT, markProductAsDefaultContent),
    takeEvery(UNMARK_PRODUCT_AS_DEFAULT_CONTENT, unmarkProductAsDefaultContent),
    takeEvery(HIDE_PRODUCT, hideProduct),
  ]);
}
