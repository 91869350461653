import { SET_STATS } from '@store/stats/actions';

const initialState = {
  stats: null,
};

export default function statsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATS:
      return {
        ...state,
        stats: action.payload, // potentail problem
      };
    default:
      return state;
  }
}
