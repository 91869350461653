import { forwardRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickableUpload from './ClickableUpload';
import getPreviewInputId from './getPreviewInputId';
import Typography from '@shared/components/typography';
import RenewImageIcon from '@resources/icons/RenewImageIcon';
import ImageIcon from '@resources/icons/ImageIcon';
import { getHeightAndWidthFromFile } from '@shared/utils';
import SquareWrapper from '@shared/components/page-parts/SquareWrapper';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    border: `.1rem dashed ${theme.palette.common.divider}`,
    background: '#FCFDFF',
    borderRadius: '.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  noFile: {
    width: '100%',
    height: 'initial',
    cursor: 'pointer',
  },
  renew: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    borderTop: `1px solid ${theme.palette.common.divider}`,
    backgroundColor: '#E2EAF3',
  },
  renewIcon: {
    marginRight: theme.spacing(2),
  },
  renewTitle: {
    fontWeight: 600,
  },
  dropText: {
    marginBottom: theme.spacing(0.25),
    fontWeight: 600,
    '& span': {
      color: theme.palette.common.green,
    },
  },
  imageIcon: {
    display: 'block',
    margin: `0 auto ${theme.spacing(2)}px`,
  },
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.common.red,
  },
  centerer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  disabledContent: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
  },
}));

const Preview = forwardRef(
  (
    {
      name,
      value,
      supportedFormats,
      renderFileViewer,
      onChange,
      onBlur,
      renewable,
      size,
      squareShape = true,
      disabled = false,
      className,
    },
    ref,
  ) => {
    const classes = useStyles();
    const [sizeError, setSizeError] = useState(false);

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const {
        dataTransfer: { files },
      } = event;
      const { length } = files;
      const reader = new FileReader();
      const file = files[0];
      if (length) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          if (size) {
            const { width, height } = await getHeightAndWidthFromFile(file);
            if (width >= size.width && height >= size.height) {
              onChange({
                width,
                height,
                file,
                dataType: file.name.split('.').pop(),
                blob: window.URL.createObjectURL(file),
              });
              setSizeError(false);
            } else {
              setSizeError(true);
            }
          } else {
            onChange({
              file,
              dataType: file.name.split('.').pop(),
              blob: window.URL.createObjectURL(file),
            });
          }
        };
      }
    };

    const handleChange = async (event) => {
      let file = event.target.files[0];
      if (!file) {
        return;
      }
      const blob = file.slice(0, file.size);
      file = new File([blob], file.name.toLowerCase());
      if (size) {
        const { width, height } = await getHeightAndWidthFromFile(file);
        if (width >= size.width && height >= size.height) {
          onChange({
            width,
            height,
            file,
            dataType: file.name.split('.').pop(),
            blob: window.URL.createObjectURL(file),
          });
          setSizeError(false);
        } else {
          setSizeError(true);
        }
      } else {
        onChange({
          file,
          dataType: file.name.split('.').pop(),
          blob: window.URL.createObjectURL(file),
        });
      }
    };

    return (
      <>
        <div
          className={ classNames(classes.root, className) }
          onDrop={ disabled ? undefined : handleDrop }
          onDragOver={ disabled ? undefined : handleDragOver }
        >
          {!value.blob ? (
            <SquareWrapper className={ classes.centerer } wrap={ squareShape }>
              {!disabled ? (
                <ClickableUpload name={ name } className={ classes.noFile }>
                  <ImageIcon className={ classes.imageIcon } size="large" />
                  <Typography className={ classes.dropText } variant="h5" align="center">
                    Drop your file here, or <span>browse</span>
                  </Typography>
                  <Typography variant="h6" color="textSecondary" align="center">Supports: {supportedFormats}</Typography>
                </ClickableUpload>
              ) : (
                <div className={ classes.disabledContent } />
              )}
            </SquareWrapper>
          ) : (
            <>
              <SquareWrapper wrap={ squareShape }>
                {renderFileViewer({ value })}
              </SquareWrapper>

              {!disabled && renewable && (
                <ClickableUpload name={ name } className={ classes.noFile }>
                  <div className={ classes.renew }>
                    <RenewImageIcon size="large" className={ classes.renewIcon } />
                    <div>
                      <Typography variant="body1" color="textPrimary" className={ classes.renewTitle }>
                        Renew your model
                      </Typography>
                      <Typography variant="body2" color="secondary">
                        {supportedFormats}
                      </Typography>
                    </div>
                  </div>
                </ClickableUpload>
              )}
            </>
          )}
          <input
            ref={ ref }
            type="file"
            id={ getPreviewInputId(name) }
            name={ name }
            hidden
            onChange={ handleChange }
            onBlur={ onBlur }
            disabled={ disabled }
          />
        </div>
        {sizeError && (
          <Typography variant="body1" align="center" className={ classes.error }>
            Resolution is too low. Minimal resolution should be 1920x1080!
          </Typography>
        )}
      </>
    );
  },
);

export default Preview;
